/**********************************************************************/
/**********************************************************************/
.header {
	position: relative;
	display: flex;
	justify-content: space-between;
	margin: 30px;

	&__logo {
		margin: auto 0;
		transition: opacity 0.3s;

		&:hover {
			opacity: 0.6;
		}

		img {
			width: 230px;
		}
	}

	&__nav {
		display: flex;
		flex-wrap: wrap;
		margin: auto 0;
	}

	&__btn {
		display: flex;
		min-height: 30px;
		margin: auto 0 auto 15px;
		padding: 3px 5px;
		border-radius: 15px;
		background-color: #333333;
		transition: background-color 0.3s;

		img {
			height: 24px;
			margin: auto 0;
		}

		span {
			margin: auto 10px;
			font-weight: 700;
			font-size: 12px;
			line-height: 15px;
			color: #FFFFFF;
			transition: color 0.3s;
		}

		&:hover {
			background-color: #50F000;

			span {
				color: #000000;
			}
		}

		&_green {
			background-color: #50F000;
	
			span {
				color: #000000;
			}

			&:hover {
				background-color: #333333;
	
				span {
					color: #FFFFFF;
				}
			}
		}
	}

	@include is-screen-max($breakpoint-sm) {
		margin: 15px;

		&__logo {
			img {
				width: 120px;
			}
		}

		&__btn {
			min-height: 24px;
			margin-left: 5px;

			img {
				display: none;
			}

			span {
				margin-left: 5px;
				margin-right: 5px;
				font-size: 10px;
			}
		}
	}
}


/**********************************************************************/
/**********************************************************************/
.main {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	margin: 0 70px 80px;
	font-size: 18px;
	line-height: 28px;
	color: #FFFFFF;

	h1, h2, h3, h4, h5, h6 {
		margin-top: 30px;
		margin-bottom: 20px;
		font-weight: 700;
		font-size: 28px;
		line-height: 36px;

		span {
			color: #50F000;
		}
	}

	h1 {
		margin-top: 0 !important;
		font-weight: 900;
		font-size: 36px;
		line-height: 44px;
		text-transform: uppercase;
	}

	p, ul, ol {
		margin-bottom: 20px;
	}

	strong, b {
		font-weight: 700;
	}

	a {
		color: inherit;
		text-decoration: underline;
		transition: opacity 0.3s;

		&:hover {
			opacity: 0.6;
		}
	}

	img {
		max-width: 100%;
		margin: 0 auto 20px;

		&.one-half {
			width: 50%;
		}
	}

	* + img {
		margin-top: 30px;
	}

	.img-list {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		margin-bottom: 20px;

		img {
			width: calc(50% - 20px);
			margin: 0 0 auto;
		}
	}

	@include is-screen-max($breakpoint-sm) {
		margin: 0 15px 60px;
		font-size: 16px;
		line-height: 24px;

		h1, h2, h3, h4, h5, h6 {
			margin-top: 20px;
			font-size: 22px;
			line-height: 28px;
		}

		h1 {
			font-size: 28px;
			line-height: 36px;
		}

		p, ul, ol {
			margin-bottom: 15px;
		}

		ul, ol {
			padding-left: 20px;
		}

		img {
			&.one-half {
				width: 100%;
			}
		}

		* + img {
			margin-top: 20px;
		}
	}
}


/**********************************************************************/
/**********************************************************************/
.footer {
	display: flex;
	flex-wrap: wrap;
	margin: 30px 18px;

	&__icon {
		display: flex;
		flex-direction: column;
		margin: auto 12px;

		img {
			height: 24px;
			margin: auto 0;
		}

		&_bw {
			filter: brightness(0) invert(1);
			transition: filter 0.3s;
		}
	}

	a {
		transition: opacity 0.3s;

		&:hover {
			opacity: 0.6;
		}
	}

	&__payments {
		display: flex;
		justify-content: flex-start;
		width: 20%;

		.footer__icon {
			&_bw {
				&:hover {
					filter: none;
				}
			}
		}
	}

	&__apps {
		display: flex;
		justify-content: center;
		margin: auto;
	}

	&__socials {
		display: flex;
		justify-content: flex-end;
		width: 20%;
	}

	@include is-screen-max($breakpoint-md) {
		margin: 30px 22px;

		&__icon {
			margin-left: 8px;
			margin-right: 8px;
		}
	}

	@include is-screen-max($breakpoint-sm) {
		flex-direction: column;
		margin: 15px 15px 30px;
	
		&__icon {
			margin: 0 10px 10px;
		}
	
		&__payments {
			justify-content: center;
			width: 100%;
			margin: 0 auto 15px;
		}
	
		&__apps {
			width: 240px;
			flex-wrap: wrap;
			margin: 0 auto 15px;

			.footer__icon {
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	
		&__socials {
			justify-content: center;
			width: 100%;
		}
	}
}