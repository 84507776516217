/**********************************************************************/
/**********************************************************************/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');


/**********************************************************************/
/**********************************************************************/
$breakpoint-sm: 767px;
$breakpoint-md: 1023px;

@mixin is-screen-min($size) {
	@media screen and (min-width: $size + 1) {
		@content;
	}
}

@mixin is-screen-max($size) {
	@media screen and (max-width: $size) {
		@content;
	}
}


/**********************************************************************/
/**********************************************************************/
body {
	margin: 0;
	background-color: #000000;
	font: normal 400 18px / 28px "Montserrat", sans-serif;
    color: #FFFFFF;
	cursor: default;
    -webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;

	* {
		box-sizing: border-box;
		margin: 0;
	}

	a {
		display: inline-block;
		text-decoration: none;
	}

	img, svg {
		display: block;
		height: auto;
		max-width: 100%;
	}

	.for {
		&-desk {
			@include is-screen-max($breakpoint-sm) {
				display: none;
			}
		}

		&-mob {
			display: none;

			@include is-screen-max($breakpoint-sm) {
				display: block;
			}
		}
	}
}


/**********************************************************************/
/**********************************************************************/
@import 'style';